.App {
	text-align: center;
	overflow: hidden;
}

.App-logo {
	height: 4vmin;
	pointer-events: none;
	opacity: 0.5;
}

.swipper-block {
	width: 100vw;
	height: 100vh;

	background-image: linear-gradient(
		rgba(0, 0, 0, 0.5), 
		rgba(0, 0, 0, 0.5)
	  ), url('https://cdn.discordapp.com/attachments/481489084292923402/987457924957868132/unknown.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.swipper-block::before {
	position: absolute;
	content: '';
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;

	width: 100vw;
	height: 100vh;
}

.swipper-main {
	position: absolute;

	display: block;
	overflow: hidden;
	font-size: calc(10px + 2vmin);
	color: white;

	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	overflow: hidden;
}