.home-logo {
	height:  45vmin;
	pointer-events: none;
}

.home-buttons {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 3vh;

	color: rgb(220, 220, 220);
	text-transform: uppercase;
	font-size: 1.75vh;
	font-weight: 700;
	z-index: 2;
}

.home-button {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.home-button:hover {
	color: rgb(255, 255, 255);
}

.home-button svg {
	margin-right: 1vh;
}